import SimpleBar from 'simplebar';

export class GreenFeatures {
  /**
   * @type {HTMLElement}
   */
  container;
  simpleBar;
  init(page) {
    this.container = page.querySelector('.green-features');

    this.simpleBar = new SimpleBar(this.container, {
      autoHide: false,
    });
  }
}
