import GLightbox from 'glightbox';

export class GalleryPage {
  gLightBox;

  init() {
    this.gLightBox = GLightbox({
      selector: '.gallery-item',
      loop: true,
      zoomable: false,
      draggable: false,
    });
  }
}
