import { WHITE_MAP_STYLES } from '../modules/map-style';
import { getJSON, isMobile } from '../modules/functions';
import { neighborhoodScroll } from '../app';

export class Neighborhood {
  /**
   * @type {HTMLElement}
   */
  neighborhoodMap;
  /**
   * @type {HTMLElement}
   */
  mapNav;
  /**
   * @type {NodeList}
   */
  mapNavTypes;
  /**
   * @type {HTMLElement}
   */
  mapSidebar;
  posMarkers = {};
  clickMarkers = {};
  mapMarkers = {};
  map;
  /**
   * @type {HTMLElement}
   */
  page;
  /**
   * @type {string}
   */
  baseIconsPath = '/images/map/';

  constructor() {}

  /**
   *
   * @param page {HTMLElement}
   */
  init(page) {
    this.page = page;

    this.initElements();
    this.initNav();
    this.initMap();
    this.initMarkers();
    this.initPointNav();
    this.initZoom();
  }

  initElements() {
    this.neighborhoodMap = this.page.querySelector('#neighborhood-map');
    this.mapNav = this.page.querySelector('.neighborhood-map-navigation');
    this.mapNavTypes = this.page.querySelectorAll(
      '.neighborhood-map-navigation-type'
    );
    this.mapSidebar = this.page.querySelector('.neighborhood-map-sidebar');
  }

  initMap() {
    if (!this.neighborhoodMap) return;

    const lat = parseFloat(this.neighborhoodMap.dataset.lat);
    const lng = parseFloat(this.neighborhoodMap.dataset.lng);
    const zoom = parseInt(this.neighborhoodMap.dataset.zoom);

    const pos = new google.maps.LatLng(lat, lng);
    this.map = new google.maps.Map(this.neighborhoodMap, {
      zoom,
      center: pos,
      styles: WHITE_MAP_STYLES,
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scrollwheel: false,
    });

    let offsetX = 93;
    let offsetY = 92;
    let iconWidth = 186;
    let iconHeight = 150;

    const marker = new google.maps.Marker({
      position: pos,
      icon: {
        url: '/images/logo-mark.png',
        size: new google.maps.Size(iconWidth, iconHeight),
        anchor: new google.maps.Point(offsetX, offsetY),
        origin: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(iconWidth, iconHeight),
      },
      map: this.map,
      zIndex: 2,
    });
  }

  initZoom() {
    this.page
      .querySelector('.neighborhood-map-zoom-in')
      .addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() + 1);
      });
    this.page
      .querySelector('.neighborhood-map-zoom-out')
      .addEventListener('click', () => {
        this.map.setZoom(this.map.getZoom() - 1);
      });
  }

  initMarkers() {
    getJSON('/location-pois.json', (_status, data) => {
      this.mapMarkers = data['data'];

      for (let marker in this.mapMarkers) {
        const currentMarker = this.mapMarkers[marker];

        const ul = this.mapNav.querySelector(
          `.neighborhood-map-navigation-section[data-type="${currentMarker.type}"] ul`
        );

        const li = document.createElement('li');
        li.classList.add('neighborhood-map-navigation-point');
        li.setAttribute('data-id', currentMarker.id);
        li.append(currentMarker.content);
        ul.append(li);

        this.posMarkers[marker] = new google.maps.Marker({
          position: new google.maps.LatLng(
            currentMarker.lat,
            currentMarker.lng
          ),
          map: this.map,
          icon: this.getIcon(`${this.baseIconsPath}${currentMarker.type}.svg`),
          visible: currentMarker.visible,
          zIndex: 1,
        });

        this.clickMarkers[marker] = ((marker) => {
          return (e) => {
            const pos = this.posMarkers[marker].getPosition();
            this.map.panTo(pos);

            this.setDefaultMarkers();
            this.setHoverMarker(marker);

            this.mapNavTypesClearActive();
            this.mapNavTypesSetActive(currentMarker);
          };
        })(marker);

        this.posMarkers[marker].addListener('click', this.clickMarkers[marker]);
      }
    });
  }

  initNav() {
    const navigationIcons = this.mapNav.querySelectorAll(
      '.neighborhood-map-navigation-icon'
    );
    navigationIcons.forEach((el) => {
      el.addEventListener('click', (e) => {
        const t = e.currentTarget;
        const parent = t.parentElement;
        const type = parent.dataset.type;

        if (parent.classList.contains('active')) {
          if (isMobile()) {
            if (parent.classList.contains('hide-sub-nav')) {
              parent?.classList.remove('hide-sub-nav');
              return;
            } else {
              parent?.classList.remove('hide-sub-nav');
            }
          }
          parent.classList.remove('active');
          this.mapNavTypesClearActive();
          // this.setDefaultMarkers();
          this.showVisibleMarkers();
          neighborhoodScroll.destroy();
        } else {
          this.mapNav.querySelector('.active')?.classList.remove('active');
          parent.classList.add('active');
          this.mapNavTypesClearActive();
          // this.setDefaultMarkers();
          this.showVisibleMarkers();
          this.showTypeMarker(type);
          neighborhoodScroll.init(
            this.page,
            '.neighborhood-map-navigation-sub-nav-scroll'
          );
        }
      });
    });
  }

  initPointNav() {
    document.addEventListener('click', (e) => {
      if (
        e.target.matches('.neighborhood-map-navigation-point') ||
        e.target.closest('.neighborhood-map-navigation-point')
      ) {
        const t = e.target;
        let index;

        for (let marker in this.mapMarkers) {
          if (this.mapMarkers[marker].id === parseInt(t.dataset.id)) {
            index = marker;
            this.map.panTo(this.posMarkers[marker].getPosition());

            this.showMarkers();
            this.setDefaultMarkers();
            this.setHoverMarker(marker);
          }
        }

        this.page
          .querySelector('.neighborhood-map-navigation-point.active')
          ?.classList.remove('active');

        if (isMobile()) {
          this.page
            .querySelector('.neighborhood-map-navigation-section.active')
            ?.classList.add('hide-sub-nav');
        }
        t.classList.add('active');
      }
    });
  }

  mapNavTypesSetActive(currentMarker) {
    document
      .querySelector(
        `.neighborhood-map-navigation-point[data-id="${currentMarker.id}"]`
      )
      .classList.add('active');
  }

  mapNavTypesClearActive() {
    this.mapNavTypes.forEach((el) => {
      el.querySelector('.active')?.classList.remove('active');
    });
  }

  setDefaultMarkers() {
    for (let marker in this.mapMarkers) {
      const type = this.mapMarkers.find((d) => d.id === parseInt(marker) + 1)
        ?.type;
      this.posMarkers[marker].setIcon(
        this.getIcon(`${this.baseIconsPath}${type}.svg`)
      );
    }
  }

  setHoverMarker(marker) {
    const type = this.mapMarkers.find((d) => d.id === parseInt(marker) + 1)
      ?.type;
    this.posMarkers[marker].setIcon(
      this.getIcon(`${this.baseIconsPath}${type}-hover.svg`)
    );
  }

  getIcon(path) {
    return {
      url: path,
      size: new google.maps.Size(40, 48),
      anchor: new google.maps.Point(20, 40),
      origin: new google.maps.Point(0, 0),
      scaledSize: new google.maps.Size(40, 48),
    };
  }

  showMarkers() {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);
    }
  }

  showVisibleMarkers() {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);
      this.posMarkers[marker].setVisible(true);
    }
  }

  showTypeMarker(type) {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);

      if (type === this.mapMarkers[marker].type) {
        this.posMarkers[marker].setVisible(true);
      } else {
        this.posMarkers[marker].setVisible(false);
      }
    }
  }
}
