import Choices from "choices.js";
import datepicker from "js-datepicker";
import SimpleBar from "simplebar";
import barba from "@barba/core";
import { isMobile, isTablet, postJSON } from "../modules/functions";
import Handlebars from "handlebars";

export class Availability {
  /**
   * @type {HTMLElement}
   */
  page;
  selects = [];
  datepicker;
  /**
   * @type {HTMLElement}
   */
  floorPlan;
  /**
   * @type {HTMLElement}
   */
  floorPlanPopup;
  /**
   *
   * @type {boolean}
   */
  sortingInit = false;

  init(page) {
    this.page = page;

    const step01 = this.page.querySelector(".availability-step-01");
    if (step01) {
      this.initSelect();
      this.initDatePicker();
      this.initHeightStep01();
      this.initSelectBuildingFloor();
      this.initSearch();
      this.initMobile();
      this.initMobileSorting();
    }

    const step02 = this.page.querySelector(".availability-step-02");
    if (step02) {
      this.initFloorPlanPopup();
      this.initSelectUnit(step02.dataset.floor);
    }

    const step03 = this.page.querySelector(".availability-step-03");
    if (step03) {
      this.initUnitGallery();
      this.showMobileFloorPlan();
      this.initHeightPlan();

      if (isMobile()) {
        const buttons = this.page.querySelector(
          ".availability-unit-caption-buttons"
        );
        buttons.querySelectorAll(".button-outline").forEach((el) => {
          el.classList.remove("button-outline");
        });
      }
    }
  }

  initSelect() {
    this.selects = [];
    this.page.querySelectorAll(".availability-select").forEach((element) => {
      this.selects.push(
        new Choices(element, {
          searchEnabled: false,
          placeholder: true,
          itemSelectText: "",
        })
      );
    });
  }

  initDatePicker() {
    const element = this.page.querySelector(".availability-datepiker");
    const hiddenElement = this.page.querySelector(
      ".hidden-availability-datepiker"
    );
    this.datepicker = datepicker(element, {
      minDate: new Date(),
      startDate: new Date(),
      formatter: (input, date, instance) => {
        input.value = date.toLocaleDateString("en-US");
        hiddenElement.value = date.toJSON().slice(0, 10);
      },
      onSelect: () => {
        setTimeout(() => {
          this.datepicker.hide();
          element.blur();
        }, 100);
      },
    });
  }

  initHeightStep01() {
    const search = this.page.querySelector(".availability-search-wrapper");
    const building = this.page.querySelector(".availability-building-wrapper");

    if (!search || !building) return;

    this.calcHeightStep01(search, building);

    window.addEventListener("resize", () => {
      this.calcHeightStep01(search, building);
    });
  }

  initSelectBuildingFloor() {
    const elements = this.page.querySelectorAll(
      ".availability-building-plan .st2"
    );

    elements.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();

        const form = this.page.querySelector(".availability-search-form");
        let queryString;
        if (form.classList.contains("searched")) {
          let data = new FormData(form);
          queryString = new URLSearchParams(data).toString();
        } else {
          queryString = "";
        }

        if (el.classList.contains("active")) {
          const floor = el.parentElement.getAttribute("id");
          barba.go(`/availability/${floor}?${queryString}`);
        }
      });
    });
  }

  initSearch() {
    this.page
      .querySelectorAll(".availability-search-form-button")
      .forEach((el) => {
        el.addEventListener("click", (e) => {
          e.preventDefault();

          const form = this.page.querySelector(".availability-search-form");
          const formData = new FormData(form);

          postJSON("/availability/search.json", formData, (status, data) => {
            this.page
              .querySelectorAll(".availability-mobile-floor")
              .forEach((el) => {
                el.classList.add("disabled");
              });

            if (this.page.querySelectorAll(".search-svg").length) {
              this.page
                .querySelectorAll(".search-svg .st2.active")
                .forEach((el) => {
                  el.classList.remove("active");
                });
              data.forEach((el) => {
                let elm = this.page.querySelector(
                  ".search-svg #" +
                    el.building +
                    (el.floor < 10 ? "0" : "") +
                    el.floor +
                    " .st2"
                );
                elm.classList.add("active");

                let floor = this.page.querySelector(
                  '.availability-mobile-floor[data-floor="' + el.floor + '"]'
                );
                floor.classList.remove("disabled");
              });
            }

            let source = document.getElementById("units-template").innerHTML;
            let template = Handlebars.compile(source);

            this.page.querySelector(".availability-mobile-unit-list").innerHTML =
              template({ units: data });

            this.page
              .querySelector(".availability-search-form")
              .classList.add("searched");

            if (isTablet()) {
              this.page
                .querySelector(".availability-mobile")
                .classList.add("show");
              this.initMobileSorting();
            }
          });
        });
      });

    this.page
      .querySelector(".availability-explore-button")
      .addEventListener("click", (e) => {
        document.dispatchEvent(new Event("floors"));
        this.page
          .querySelector(".availability-search-form")
          .classList.remove("searched");
      });
  }

  /**
   *
   * @param search {HTMLElement}
   * @param building {HTMLElement}
   */
  calcHeightStep01(search, building) {
    if (search.clientHeight < building.clientHeight) {
      search.style.height = building.clientHeight + "px";
    } else {
      building.style.height = search.clientHeight + "px";
    }
  }

  initSelectUnit(floor) {
    const elements = this.page.querySelectorAll(
      ".availability-floorplan .svg-floor .st0"
    );
    elements.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();

        if (el.classList.contains("active")) {
          const unit = el.getAttribute("id").slice(1);
          barba.go(`/availability/${floor}/${unit}`).then();
        }
      });
    });
  }

  initFloorPlanPopup() {
    this.floorPlan = this.page.querySelector(
      ".availability-step-02 .availability-floorplan"
    );
    this.floorPlanPopup = this.page.querySelector(
      ".availability-floorplan-popup"
    );

    this.setFloorPlanPopupEvent();
  }

  setFloorPlanPopupEvent() {
    this.floorPlan.querySelectorAll(".st0").forEach((el) => {
      el.addEventListener("mouseenter", () => {
        if (!el.classList.contains("active")) return;
        this.floorPlanPopup.style.left =
          el.getBoundingClientRect().left - el.getBBox().width / 2 + "px";
        this.floorPlanPopup.style.top =
          el.getBoundingClientRect().top - 60 + "px";
        this.floorPlanPopup.style.display = "flex";

        let id = el.id.slice(1);
        let content = "";
        content += `UNIT ${id}`;
        if (window.units_info[id]) {
          content += `<br>${window.units_info[id].layout}`;
          content += `<br>${window.units_info[id].available_date}`;
        }

        this.floorPlanPopup.innerHTML = content;
      });

      el.addEventListener("mouseleave", () => {
        if (!el.classList.contains("active")) return;
        this.floorPlanPopup.style.display = "none";
      });
    });
  }

  initUnitGallery() {
    const gallery = this.page.querySelector(".availability-unit-gallery");
    const galleryButton = this.page.querySelector(
      ".availability-unit-caption-buttons-gallery"
    );
    const galleryClose = this.page.querySelector(
      ".availability-unit-gallery-close"
    );

    galleryButton.addEventListener("click", (e) => {
      e.preventDefault();
      gallery.style.display = "block";
    });

    galleryClose.addEventListener("click", (e) => {
      e.preventDefault();
      gallery.style.display = "none";
    });

    const galleryContent = this.page.querySelector(
      ".availability-unit-gallery-content"
    );

    if (galleryContent) {
      new SimpleBar(galleryContent, {
        autoHide: false,
      });
    }
  }

  initMobile() {
    const mobile = this.page.querySelector(".availability-mobile");
    mobile
      .querySelector(".availability-mobile-close")
      .addEventListener("click", () => {
        mobile.classList.remove("show");
      });

    mobile.querySelectorAll(".availability-mobile-floor").forEach((el) => {
      el.addEventListener("click", () => {
        if (el.classList.contains("disabled")) return;

        if (el.classList.contains("active")) {
          el.classList.remove("active");
          this.showAllMobileUnits();
        } else {
          mobile
            .querySelector(".availability-mobile-floor.active")
            ?.classList.remove("active");
          el.classList.add("active");

          const floor = el.dataset.floor;
          this.showAllMobileUnits();
          if (floor) {
            mobile
              .querySelectorAll(
                `.availability-mobile-unit:not([data-floor="${floor}"])`
              )
              .forEach((el) => {
                el.classList.add("hide");
              });
          }
        }
      });
    });
  }

  showAllMobileUnits() {
    this.page
      .querySelectorAll(".availability-mobile-unit.hide")
      .forEach((el) => {
        el.classList.remove("hide");
      });
  }

  showMobileFloorPlan() {
    const plan = this.page.querySelector(".availability-unit-plan");
    const innerContainer = this.page.querySelector(".inner-container");
    // console.log(innerContainer);
    this.page
      .querySelector(".availability-unit-caption-buttons-floorplan")
      .addEventListener("click", () => {
        if (plan.classList.contains("show")) {
          innerContainer.classList.remove("overflow-hidden");
          plan.classList.remove("show");
        } else {
          plan.classList.add("show");
          innerContainer.classList.add("overflow-hidden");
        }
      });

    this.page
      .querySelector(".availability-unit-plan-close")
      .addEventListener("click", () => {
        plan.classList.remove("show");
        innerContainer.classList.remove("overflow-hidden");
      });
  }

  initHeightPlan() {
    if (!isTablet()) {
      this.setHeightPlan();
      window.addEventListener("resize", this.setHeightPlan);
    }
  }

  setHeightPlan() {
    const height = this.page
      .querySelector(".availability-unit-caption")
      .getBoundingClientRect().height;
    console.log(height);
    this.page.querySelector(
      ".availability-unit-plan"
    ).style.height = `${height}px`;
  }

  setFilteredFloors() {
    if (typeof available_floors != "undefined") {
      available_floors.forEach(function (floor) {
        $(".search-svg #" + floor + " .st2").addClass("active");
      });
    }
  }

  setFilteredUnits() {
    if (typeof available_units != "undefined") {
      available_units.forEach(function (unit) {
        $("#x" + unit).addClass("active");
      });
    }
  }

  initMobileSorting() {
    if (!this.sortingInit) {
      this.page
        .querySelectorAll(".availability-mobile-sort-button")
        .forEach((el) => {
          el.addEventListener("click", () => {
            if (!el.classList.contains("active")) {
              this.page
                .querySelector(".availability-mobile-sort-button.active")
                ?.classList.remove("active");
              el.classList.add("active");
              this.mobileSorting(el.dataset.sort);
            }
          });
        });
    }

    this.page
      .querySelector('.availability-mobile-sort-button[data-sort="type"]')
      ?.classList.add("active");
    this.mobileSorting("type");
    this.sortingInit = true;
  }

  /**
   *
   * @param sort {string}
   */
  mobileSorting(sort) {
    const list = this.page.querySelector(".availability-mobile-unit-list");
    /**
     *
     * @type {[{bedrooms:string,price:string,element: Element}]}
     */
    let unitArray = [];
    list.querySelectorAll(".availability-mobile-unit").forEach((el) => {
      unitArray.push({
        bedrooms: el.dataset.bedrooms,
        price: el.dataset.price,
        element: el,
      });
    });

    if (sort === "type") {
      unitArray.sort((a, b) => {
        if (a.bedrooms > b.bedrooms) {
          return 1;
        }
        if (a.bedrooms < b.bedrooms) {
          return -1;
        }
        return 0;
      });
    }

    if (sort === "price") {
      unitArray.sort((a, b) => {
        if (a.price > b.price) {
          return 1;
        }
        if (a.price < b.price) {
          return -1;
        }
        return 0;
      });
    }

    list.innerHTML = "";
    unitArray.forEach((unit) => {
      list.append(unit.element);
    });
  }
}
