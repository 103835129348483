import Cookies from "js-cookie";
export class Popup {
  /**
   * @private
   * @type {number}
   */
  animationDuration = 600;
  /**
   * @private
   * @type {HTMLElement}
   */
  body = null;

  init() {
    this.body = document.querySelector("body");
    this.openInit();
    this.closeInit();
    this.openDefaultPopup();
  }

  /**
   * @private
   */
  openInit() {
    document.addEventListener("click", (e) => {
      if (!e.target.matches(".popup-open")) return;
      e.preventDefault();
      /**
       *
       * @type {HTMLElement}
       */
      const target = e.target;
      /**
       *
       * @type {string}
       */
      const href = target.getAttribute("href");
      /**
       *
       * @type {string}
       */
      const dataPopup = target.dataset["popup"];

      /**
       * @type {HTMLElement}
       */
      let popup;

      if (href && href !== "#") {
        popup = document.querySelector(href);
      }

      if (dataPopup) {
        popup = document.querySelector(`#${dataPopup}`);
      }

      if ((!href && !dataPopup) || !popup) {
        console.error("Couldn't find a popup");
        return;
      }

      this.open(popup);
    });
  }

  /**
   * @private
   */
  closeInit() {
    document.addEventListener("click", (e) => {
      if (
        e.target.matches(".popup-close") ||
        e.target.closest(".popup-close")
      ) {
        e.preventDefault();
        /**
         *
         * @type {HTMLElement}
         */
        const target = e.target;

        this.close(target.closest(".popup"));
      }
    });

    /*Close in outside container*/

    document.addEventListener("click", (e) => {
      if (!e.target.matches(".popup")) return;
      e.preventDefault();
      /**
       *
       * @type {HTMLElement}
       */
      const target = e.target;

      this.close(target);
    });
  }

  /**
   *
   * @param popup {HTMLElement}
   */
  open(popup) {
    this.body.classList.add("overflow-hidden");
    popup.classList.add("popup-show");
  }

  /**
   *
   * @param popup {HTMLElement}
   */
  close(popup) {
    this.body.classList.remove("overflow-hidden");
    popup.classList.add("popup-hide");

    setTimeout(() => {
      popup.classList.remove("popup-show");
      popup.classList.remove("popup-hide");
    }, this.animationDuration);
  }

  openDefaultPopup() {
    let $this = this;
    if (typeof Cookies.get("popup") == "undefined") {
      setTimeout(function () {
        $this.open(document.querySelector("#cookie-popup"));
        Cookies.set("popup", "1", { expires: 30, path: "/" });
      }, 3000);
    }
  }
}
