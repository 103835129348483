import SimpleBar from 'simplebar';
import { secondPage } from '../app';

export class ScrollSecondContent {
  /**
   *
   * @type {{event: boolean, el: HTMLElement}[]}
   */
  elements = [];

  /**
   *
   * @param page {HTMLElement}
   * @param className {string}
   */
  init(page, className) {
    this.elements = [];

    page.querySelectorAll(className).forEach((el, i) => {
      if (!el.querySelector('.green-features')) {
        const simpleBar = new SimpleBar(el, {
          autoHide: false,
        });

        this.elements.push({
          event: false,
          el,
        });

        this.checkScroll(i, simpleBar.getContentElement());
      }
    });
  }

  /**
   *
   * @param index {number}
   * @param contentElement {HTMLElement}
   */
  checkScroll(index, contentElement) {
    const element = this.elements[index];
    if (
      element.el.clientHeight < contentElement.clientHeight &&
      secondPage?.isLive()
    ) {
      element.el.addEventListener('mouseenter', this.stopFullPage);
      element.el.addEventListener('mouseleave', this.playFullPage);
      element.event = true;
    }
  }

  stopFullPage(event) {
    secondPage.stop();
  }

  playFullPage(event) {
    secondPage.play();
  }

  destroy() {
    this.elements?.map((element) => {
      if (element.event) {
        element.el.removeEventListener('mouseenter', this.stopFullPage);
        element.el.removeEventListener('mouseleave', this.playFullPage);
      }
    });

    this.elements = [];
  }
}
