// import 'fullpage.js/dist/fullpage.min.css';
// import 'simplebar/dist/simplebar.css';
// import 'glightbox/dist/css/glightbox.min.css';
// import 'choices.js/public/assets/styles/choices.min.css';
// import 'js-datepicker/dist/datepicker.min.css';
// import '../scss/app.scss';
import { Barba } from './modules/barba';
import {
  ActiveNavigation,
  Burger,
  ContactMap,
  GreenFeatures,
  Popup,
  ScrollSecondContent,
} from './components';
import { Availability, GalleryPage, Neighborhood, SecondPage } from './pages';

/* Global */
export const barbaApp = new Barba();
export const activeNavigation = new ActiveNavigation();
export const burger = new Burger();
export const popup = new Popup();

/* Second */
export const secondPage = new SecondPage();
export const greenFeatures = new GreenFeatures();
export const scrollSecondContent = new ScrollSecondContent();

/* Gallery */
export const galleryPage = new GalleryPage();

/* Contact */
export const contactMap = new ContactMap();

/* Neighborhood */
export const neighborhood = new Neighborhood();
export const neighborhoodScroll = new ScrollSecondContent();

/* Availability */
export const availability = new Availability();

window.addEventListener('DOMContentLoaded', () => {
  init();
});

function init() {
  /* Global */
  barbaApp.init();
  activeNavigation.init();
  burger.init();
  popup.init();

  $(document).on('floors', function(){
    availability.setFilteredFloors();
  })

  $(document).on('units', function(){
    availability.setFilteredUnits();
  })
}
