import barba from '@barba/core';
import {
  activeNavigation,
  availability,
  contactMap,
  galleryPage,
  greenFeatures,
  neighborhood,
  neighborhoodScroll,
  scrollSecondContent,
  secondPage,
} from '../app';

export class Barba {
  body;

  constructor() {}

  init() {
    this.body = document.querySelector('body');

    this.hooksInit();
    this.viewsInit();
  }

  hooksInit() {
    barba.hooks.enter(() => {
      window.scrollTo(0, 0);
    });

    barba.hooks.after((data) => {
      var js = data.next.container.querySelectorAll("script:not([type='text/x-handlebars-template'])");
      if (js.length) {
        js.forEach((item) => {
          if (item.src) {
            $.getScript(item.src);
          } else {
            eval(item.innerHTML);
          }
        });
      }
    });
  }

  viewsInit() {
    barba.init({
      debug: process.env.NODE_ENV != "production",
      prevent: ({ el }) => {
        let prevent = false;
        if (el.classList?.contains('popup-open')) {
          prevent = true;
        }
        if (el.classList?.contains('gallery-item')) {
          prevent = true;
        }

        return prevent;
      },
      views: [
        {
          namespace: 'home',
          afterEnter: (data) => {
            this.allAfterEnter(data.next.container, true);
          },
          beforeLeave: (data) => {
            this.allBeforeLeave();
          },
        },
        {
          namespace: 'second',
          afterEnter: (data) => {
            secondPage.init(data.next.container);
            this.allAfterEnter(data.next.container);

            if (data.next.container.querySelector('.green-features')) {
              greenFeatures.init(data.next.container);
            }
          },
          beforeLeave: (data) => {
            secondPage.destroy();
            this.allBeforeLeave();
          },
        },
        {
          namespace: 'availability',
          afterEnter: (data) => {
            this.allAfterEnter(data.next.container);
            availability.init(data.next.container);
          },
          beforeLeave: (data) => {
            this.allBeforeLeave();
          },
        },
        {
          namespace: 'gallery',
          afterEnter: (data) => {
            this.allAfterEnter(data.next.container);
            galleryPage.init();
          },
          beforeLeave: (data) => {
            this.allBeforeLeave();
          },
        },
        {
          namespace: 'contact',
          afterEnter: (data) => {
            this.allAfterEnter(data.next.container);
            contactMap.init(data.next.container);
          },
          beforeLeave: (data) => {
            this.allBeforeLeave();
          },
        },
        {
          namespace: 'neighborhood',
          afterEnter: (data) => {
            secondPage.init(data.next.container);
            this.allAfterEnter(data.next.container);
            neighborhood.init(data.next.container);
          },
          beforeLeave: (data) => {
            this.allBeforeLeave();
            secondPage.destroy();
          },
        },
        {
          namespace: 'privacy',
          afterEnter: (data) => {
            this.allAfterEnter(data.next.container);
          },
          beforeLeave: (data) => {
            this.allBeforeLeave();
          },
        },
      ],
      transitions: [
        {
          name: 'to-all',
          enter: () => {},
          leave: (data) => {},
          after: (data) => {},
        },
      ],
    });
  }

  /**
   *
   * @param page {HTMLElement}
   * @param headerTransparent {boolean}
   */
  allAfterEnter(page, headerTransparent = false) {
    activeNavigation.setActive();
    scrollSecondContent.init(page, '.second-page-content-center');

    if (headerTransparent) {
      this.body.classList.add('header-transparent');
      this.body.classList.add('overflow-hidden');
    } else {
      this.body.classList.remove('header-transparent');
      this.body.classList.remove('overflow-hidden');
    }
  }

  allBeforeLeave() {
    scrollSecondContent.destroy();
  }
}
