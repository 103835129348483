export class ActiveNavigation {
  navigation;

  constructor() {
  }

  init() {
    this.navigation = document.querySelector('.header-navigation');
  }

  setActive() {
    const path = window.location.pathname.split('/');

    if (path[1] && path[1].length > 0) {
      this.removeActive();
      this.navigation.querySelector(`a[href^="/${path[1]}"]`)?.classList.add('active');
    } else {
      this.removeActive();
    }
  }

  removeActive() {
    this.navigation.querySelector(`.active`)?.classList.remove('active');
  }
}
