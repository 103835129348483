import { WHITE_MAP_STYLES } from '../modules/map-style';

export class ContactMap {
  /**
   * @type {HTMLElement}
   */
  mapElement;
  map;
  /**
   *
   * @param page {HTMLElement}
   */
  init(page) {
    this.mapElement = page.querySelector('.contact-map');

    if (!this.mapElement) return;

    const lat = parseFloat(this.mapElement.dataset.lat);
    const lng = parseFloat(this.mapElement.dataset.lng);
    const zoom = parseInt(this.mapElement.dataset.zoom);

    const pos = new google.maps.LatLng(lat, lng);
    this.map = new google.maps.Map(this.mapElement, {
      zoom,
      center: pos,
      styles: WHITE_MAP_STYLES,
      disableDefaultUI: true,
    });

    let offsetX = 93;
    let offsetY = 92;
    let iconWidth = 186;
    let iconHeight = 150;

    const marker = new google.maps.Marker({
      position: pos,
      icon: {
        url: '/images/logo-mark.png',
        size: new google.maps.Size(iconWidth, iconHeight),
        anchor: new google.maps.Point(offsetX, offsetY),
        origin: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(iconWidth, iconHeight),
      },
      map: this.map,
    });
  }
}
