import FullPage from 'fullpage.js/dist/fullpage';
import SimpleBar from 'simplebar';
import { isMobile, scrollToElement } from '../modules/functions';

export class SecondPage {
  /**
   * @type {string[]}
   */
  anchors = [];
  /**
   * @type {HTMLElement}
   */
  fullPageElement;
  /**
   * @type {HTMLElement}
   */
  page;
  /**
   * @type {HTMLElement}
   */
  navigation;
  /**
   * @type {boolean}
   */
  initialization = true;
  /**
   * @type {boolean}
   */
  stopFullPage = false;
  fullPage;

  init(page) {
    this.page = page;

    this.fullPageElement = this.page.querySelector('.full-page');
    this.navigation = this.page.querySelector('.second-page-navigation');

    if (this.fullPageElement) {
      this.initFullPage();
    }

    if (isMobile()) {
      if (this.navigation) {
        this.initScrollNavigation();
        this.activeNavigationMobile();
      }
      this.goNextSectionMobile();
    }
  }

  initFullPage() {
    this.setAnchors();

    this.fullPage = new FullPage(this.fullPageElement, {
      anchors: this.anchors,
      sectionSelector: '.full-page-section',
      scrollOverflow: false,
      normalScrollElements: '.green-features',
      responsiveWidth: 768,
      licenseKey: 'E90D1C50-892F453C-A1A82D97-FBB949FC',
      afterLoad: (origin, destination) => {
        const datasetNavigation = destination.item.dataset['navigation'];

        if (
          datasetNavigation &&
          this.navigation &&
          datasetNavigation !== 'footer'
        ) {
          this.changeActiveNavigation(datasetNavigation);
        } else if (this.navigation && destination.anchor !== 'footer') {
          this.changeActiveNavigation(destination.anchor);
        }
      },
      onLeave: () => {
        // console.log(this.stopFullPage);
        return !this.stopFullPage;
      },
    });

    if (!window.location.hash) {
      this.fullPage.silentMoveTo(1);
    }
  }

  initScrollNavigation() {
    const simpleBar = new SimpleBar(this.navigation, {
      autoHide: false,
    });
  }

  setAnchors() {
    this.anchors = [];

    this.fullPageElement
      .querySelectorAll('.full-page-section')
      .forEach((el) => {
        this.anchors.push(el.dataset['anchor']);
      });
  }

  changeActiveNavigation(anchor) {
    this.removeActiveNavigation();

    if (anchor !== 'footer') {
      this.navigation
        .querySelector(`a[href^="#${anchor}"]`)
        ?.classList.add('active');

      this.navigation.querySelector(`a[href^="#${anchor}"]`).scrollIntoView();
    }
  }

  activeNavigationMobile() {
    this.navigation.querySelectorAll('a').forEach((el) => {
      el.addEventListener('click', (e) => {
        this.scrollTo(e.target);
      });
    });
  }

  goNextSectionMobile() {
    this.page.querySelectorAll('.second-page-content-next').forEach((el) => {
      el.addEventListener('click', (e) => {
        this.scrollTo(e.target, 0);
      });
    });
  }

  /**
   *
   * @param el {HTMLElement}
   * @param offset {number}
   */
  scrollTo(el, offset = -90) {
    const hash = el.getAttribute('href');
    const section = this.page.querySelector(
      `[data-anchor="${hash.slice(1, hash.length)}"]`
    );

    // console.log(hash);

    scrollToElement(section, offset);
  }

  removeActiveNavigation() {
    this.navigation.querySelector('.active')?.classList.remove('active');
  }

  stop() {
    // this.fullPage.setAllowScrolling(false);
    this.stopFullPage = true;
  }

  play() {
    // this.fullPage.setAllowScrolling(true);
    this.stopFullPage = false;
  }

  isLive() {
    return !!this.fullPage;
  }

  destroy() {
    this.fullPage.destroy('all');
    this.fullPage = null;
    document.querySelector('html').classList.remove('fp-enabled');
    this.initialization = false;
  }
}
